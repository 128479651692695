@use '../settings/variables';
@use '../settings/mixins';

.menu-panel {
  $_className: '.menu-panel';

  @include mixins.center-column();

  & {
    margin-bottom: 23px;
  }

  &__panel {
    width: 100%;

    &:not(:first-child) {
      margin-top: 9px;
    }

    background-color: #ffffff;
    box-shadow: 0 2px 1px rgba(149, 149, 148, 0.27);
  }

  &__item {
    display: inline-block;
    height: 100%;
    margin: 0 1px;
  }

  &__link {
    display: block;
    color: var(--menu-panel_link_color);
    text-decoration: none;
    font-size: 16px;

    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;

    @media (max-width: variables.$device_xs-max) {
      padding: 6px 10px;
    }
    @media (min-width: variables.$device_sm-min) {
      padding: 8px 16px;
    }
    @media (min-width: variables.$device_md-min) {
      padding: 10px 22px;
    }
  }

  // Модификаторы
  &__item_active > #{$_className}__link, &__item:hover > #{$_className}__link {
    border-bottom-color: #fc973d;
  }

  &__item_sub, &__item_sub:hover {
    border-color: #fed442;
  }

  &__link_sub {
    color: #767676;
    font-size: 14px;
  }
}
