@use '../settings/mixins';

.ls-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  outline: 0;
  z-index: 1060;
  top: 0;

  &:not(:last-of-type) {
    visibility: hidden;
  }

  // Стили окна с учетом склассов добавляемых компонентом модальных окон
  // Выбор прямых потомков ">" сделан для избежания побочных эффектов
  // при вложении блоков окон друг в друга
  & > .modal-dialog {
    width: 100%;
    height: 100%;
    overflow: auto;

    display: flex;
    align-items: center;
    justify-content: space-around;

    // Хак для корректного центрирования по вертикали в IE11
    flex-wrap: wrap;
    &:before, &:after {
      content: '';
      width: 100%;
      flex-shrink: 0;
      flex-grow: 1;
      display: block;
      height: 0;
    }
    // /Хак

    & > .modal-content {
      max-width: 100%;
      margin: auto;
      position: relative;
      background: #fff;
      box-shadow: 0 0 10px rgba(166, 166, 166, 0.63);

      &:before, &:after {
        // Реализация отступа сверху и снизу в процентах от собственной ширины
        content: '';
        display: block;
        padding: var(--modal_padding) 0 0;
      }
    }
  }

  // Модификатор - показ модального окна во весь экран
  &_full-screen > .modal-dialog {
    display: block;
    &:before, &:after {
      display: none;
    }
    & > .modal-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      min-height: 100%;
      > * {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        min-height: 100%;
        &:before, &:after {
          display: none;
        }
      }
    }
  }

  //-------------------------
  // Секция заголовка окна
  &__header {
    padding: 0 var(--modal_padding);
    position: relative;
    margin-bottom: 17px;
  }
  &__header-title {
    // Текст заголовка окна
    font-weight: var(--font-weight_normal);
    color: var(--page_header_bg-color);
    margin: 0 45px 0 0;
    font-size: 26px;
  }

  &__message {
    // Текст сообщения для пользователя
    @include mixins.micro-clearfix();
    padding: 0 var(--modal_padding);
    color: var(--text_base_color);
    margin: 15px var(--modal_padding--negative);
    min-height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
  }
  &__message_alert {
    background-color: var(--text_alert_color);
    color: #ffffff;
  }

  // Блок списка ролей
  &__roles {
    @include mixins.micro-clearfix();
    padding: 10px var(--modal_padding);
    color: var(--text_base_color);
    margin: 15px var(--modal_padding--negative);
    min-height: 40px;
    //padding: 10px 0;
    font-size: 16px;

    background-color: #f8f8f8;
    border-top: 1px solid var(--block_divider_color);
    border-bottom: 1px solid var(--block_divider_color);
  }

  // Контент окна
  &__body {
    flex-grow: 1;
    padding: 0 var(--modal_padding);
    border: none;
  }

  // Подвал окна (для кнопок)
  &__footer {
    @include mixins.micro-clearfix();
    padding: 0 var(--modal_padding);
    margin-top: 4px;
    text-align: center;
  }
}

// Темная подложка
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: .5;
  &:not(:last-of-type) {
    visibility: hidden;
  }
}

// Стиль устанавливается body, для отмены скролла во время показа модального окна
// Сам стиль устанавливается компонентом модалок
@at-root {
  .modal-open {
    overflow: hidden;
  }
}
