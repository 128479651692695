@use "sass:math";

// Форма "Платежное поручение" - таблица
.ls-payment-order {
  // VARIABLES
  $table-cell_padding: 1.5mm;

  // STYLES
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;

  b {
    font-weight: var(--font-weight_bolder);
  }

  // Общее оформление ячеек
  td {
    padding: $table-cell_padding;
    border: 1px solid black;
    vertical-align: top;
  }

  // Оформление секций "Банк получателя", "Получатель"
  tr:nth-child(1) td:nth-child(1),
  tr:nth-last-child(2) td:nth-child(1) {
    border-bottom: none;
  }

  tr:nth-child(3) td:nth-child(1),
  tr:nth-last-child(1) td:nth-child(1) {
    font-size: 0.9em;
    border-top: none;
    $padding-bottom: math.div($table-cell-padding, 3);
    padding: 0 $table-cell_padding $padding-bottom;
    vertical-align: bottom;
  }
}
