@use '../settings/mixins';

.ls-datepicker {
  th {
    height: inherit;
    padding: 0;
  }

  tr, td {
    border: none !important;
    background-color: white;
  }

  td {
    height: 30px;
    padding: 0;
  }

  tr:hover {
    border: none !important;

    td {
      background-color: inherit !important;
    }
  }

  .header {
    min-width: inherit;
    display: table;
  }

  .mydp {
    @include mixins.form-control();

    & {
      height: var(--control_height);
      display: block;
    }

    .selectiongroup, input {
      font-family: inherit;
      font-size: inherit !important;
      display: block;
      position: relative;
    }

    .selectiongroup, .selbtngroup, input {
      height: 100% !important;
    }

    .selbtngroup {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: auto;
    }

    .mydpicon {
      color: var(--page_header_bg-color);
    }

    .icon-mydpremove {
      @include mixins.cross(15px, var(--page_header_bg-color), var(--page_header_bg-color--darken_20), 3px);

      & {
        display: inline-block;
      }
    }

    .btnpicker {
      border-radius: inherit;
    }

    .tablesingleday:hover, .tablesinglemonth:hover, .tablesingleyear:hover {
      background-color: #DDD !important;
    }

    .daycell {
      color: #036;
    }

    .currmonth {
      &:hover {
        background-color: #DDD !important;
      }
    }

    .weekdaytitle {
      padding: 4px;
    }

    .selection {
      padding-left: 8px;
    }
  }
}
