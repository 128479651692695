.ls-price-link {
  padding: 4px;
  text-decoration: none;
  color: var(--table_header_color);
  background: var(--table_header_bg-color--lighten_5);
  margin-right: var(--block_space);

  &_base {
    background: var(--table_header_bg-color);
  }

  &_active {
    background: var(--table_header_bg-color--darken_5);
  }
}

.ls-price-copy {
  color: var(--table_header_color);
  cursor: pointer;
}
