.ls-page-print-expenditure {
  // PARAMETERS
  $table-cell_padding: 1.5mm;
  $table_border-width: 1px;

  // STYLES
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 9pt;

  @media screen {
    padding: 10mm;
  }

  & table {
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;

    & th, & td {
      border: $table_border-width solid;
      padding: $table-cell_padding;
    }
  }

  &__title {
    font-size: 14pt;
    font-weight: var(--font-weight_bolder);
    margin: 0 0 4mm;
    &:not(:first-of-type) {
      margin-top: 10mm;
    }
  }

  &__position-root td {
    font-weight: var(--font-weight_bolder);
  }

  &__position-child td:first-child {
    padding-left: $table-cell_padding * 2;
  }
}
