@use '../settings/variables';

/*
Блок сортировки.
Отвечает за показ иконки сортировки.
*/
.ls-sort {
  $_className: '.ls-sort';
  // Variables
  $icon-size: 10px;
  $icon-rounded-padding: 3px;
  $icon-rounded-size: $icon-size + $icon-rounded-padding * 2 + 1;

  // Styles
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  // Text link
  &__link {
    border-bottom: 1px dashed transparent;
    white-space: normal;
    word-wrap: break-word;
    //word-break: break-all;
  }

  &:hover #{$_className}__link {
    border-bottom-color: inherit;
  }

  // Icon
  &__icon {
    flex-shrink: 0;
    vertical-align: middle;
    transform: rotate(180deg);
    width: $icon-size;
    height: $icon-size;
    fill: var(--page_header_bg-color);

    @media (min-width: variables.$device_md-min) {
      margin-left: 5px;
    }
  }

  &:hover #{$_className}__icon {
    fill: var(--page_header_bg-color--darken_10);
  }

  // Desc icon
  &__icon_desc {
    transform: rotate(0deg);
  }

  // Active icon
  &__icon_active {
    fill: #ffffff;
    background-color: var(--page_header_bg-color);
    width: $icon-rounded-size;
    height: $icon-rounded-size;
    padding: $icon-rounded-padding;
    border-radius: 0.5 * ($icon-rounded-size);
  }

  &:hover #{$_className}__icon_active {
    fill: #ffffff;
    background-color: var(--page_header_bg-color--darken_10);
  }
}
