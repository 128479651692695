@use '../settings/mixins';

// styles for component the tree-root
.ls-tree-control {
  font: inherit;
  height: 100%;

  & .angular-tree-component {
    cursor: pointer;
  }

  & .node-content-wrapper-active.node-content-wrapper-focused {
    background: #beebff;
  }

  & .node-content-wrapper-active,
  & .node-content-wrapper-focused,
  & .node-content-wrapper:hover {
    box-shadow: inset 0 0 1px #999;
  }

  & .node-content-wrapper:hover {
    background: #f7fbff;
  }

  &__node {
    display: flex;
  }

  &__node-text {
    flex-grow: 1;
  }

  &__node-actions {
    display: flex;
  }

  & .node-drop-slot {
    display: block;
    height: 2px;

    &.is-dragging-over {
      background: #ddffee;
      height: 20px;
      border: 2px dotted #888;
    }
  }

  & .node-wrapper {
    display: flex;
    align-items: flex-start;
  }

  & .node-content-wrapper {
    flex-grow: 1;
    display: inline-block;
    padding: 2px 5px;
    border-radius: 2px;
    transition: background-color .15s, box-shadow .15s;

    &.is-dragging-over {
      background: #ddffee;
      box-shadow: inset 0 0 1px #999;
    }
  }

  & .tree-node-loading {
    display: none;
  }

  & .tree-children {
    padding-left: 20px;
    overflow: hidden;
  }

  & .toggle-children-wrapper, & .toggle-children-placeholder {
    display: inline-block;
    width: 20px;
    padding: 0;
  }

  & .toggle-children-placeholder {
    border-width: 0 0 1px 0;
    height: 10px !important;
    padding: 0 !important;
    border-style: solid;
    color: var(--page_header_bg-color);
    margin-bottom: 5px;
  }

  & .toggle-children {
    background: none;
    color: var(--page_header_bg-color);
  }

  & .toggle-children-wrapper-collapsed .toggle-children {
    @include mixins.arrow(45deg, 15px, 3px);
    margin-left: 3px;
  }

  & .toggle-children-wrapper-expanded .toggle-children {
    @include mixins.arrow(135deg, 15px, 3px);
    margin-left: 4px;
    margin-bottom: 3px;
  }
}
