/* You can add global styles to this file, and also import other style files */
@forward "styles/theme";

// Common document styling
html, body {
  height: 100%;
}

body {
  background-color: var(--page_bg-color);
  margin: 0;
  @media print {
    background-color: white;
  }
}

body, input, textarea, select, button {
  font-size: 100%;
  font-family: 'Roboto', sans-serif;
}

[hidden] {
  display: none !important;
}

router-outlet {
  display: none;
}
