@use '../settings/variables';

.ls-catalog {
  @media (max-width: variables.$device_sm-max) {
    display: block;
  }
  @media (min-width: variables.$device_md-min) {
    display: flex;
    justify-content: space-between;
  }

  &__categories {
    min-width: 250px;
    flex-basis: 0;
    flex-grow: 25;
  }

  &__positions {
    flex-basis: 0;
    flex-grow: 75;
  }

  &__img {
    max-width: 100%;
    max-height: 60px;
  }

  &__popover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, .3);
    border-radius: 3px;
    z-index: 1000;
    background: white;

    img {
      max-width: 60vw;
      max-height: 60vh;
      display: block;
    }
  }
}

.ls-catalog-old {
  // Variables
  $head_height: 30px;
  $category_width: 255px;
  $section-space: 15px;

  // Styles
  &__section {
    position: relative;
    min-height: 30px;

    &:not(:last-of-type) {
      margin-bottom: $section-space;
    }
  }

  &__section_main {
    min-height: 500px;
  }

  &__category {
    margin: 0;
    width: $category_width;
    position: absolute;
    //top: 0;
    //left: 0;
    //bottom: 0;
    float: left;
    height: 100%;
  }

  &__positions {
    margin: 0 0 0 $category_width + $section-space;
  }

}
