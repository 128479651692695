@use '../settings/mixins';

.user-menu {
  $_className: '.user-menu';

  // Миксины блока
  @mixin item() {
    position: relative;
    display: block;
    text-align: left;
    font-size: 15px;
    color: #767676;
    min-width: 150px;
    padding: 0;
  }

  // Стили блока
  display: block;

  &__link {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: var(--user-menu_icon-man_bg-color);
    border: 1px solid #fff;
    width: 36px;
    height: 36px;
    margin: 0;
    cursor: pointer;
  }

  &__link-icon {
    display: inline-block;
    fill: #fff;
    height: 34px;
    width: 34px;
    pointer-events: none; // IE-fix
  }

  &__item-user {
    @include item();

    p {
      margin: 0;
      padding-bottom: 3px;
    }
  }

  &__item-profile {
    @include item();

    a {
      color: var(--menu-panel_link_color);
      padding: 0 0 19px 0;
      display: block;
    }
  }

  &__item-exit {
    @include item();
    @include mixins.vertical-align();
    @include mixins.button-background-color(var(--page_header_bg-color), var(--page_header_bg-color--darken_10));

    & {
      height: var(--toolbar_height);
      text-align: center;
      cursor: pointer;
    }
  }

  &__item-exit-icon {
    width: 18px;
    height: 18px;
    fill: #fff;
  }

  &__item-exit-text {
    color: #ffffff;
    font-weight: var(--font-weight_bold);
    text-align: center;
    text-decoration: none;
    padding: 0;
    display: inline-block;
  }

  &__item-settings {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
  }

  &__item-settings-icon {
    $settings-size: 30px;

    width: $settings-size;
    height: $settings-size;
    fill: var(--page_header_bg-color);
    cursor: pointer;

    &:hover, &:focus {
      fill: var(--page_header_bg-color--darken_10);
      outline: 0;
    }
  }
}
