@use "sass:math";
@use '../settings/mixins';

// Классы для работы с сеткой, аналогично bootstrap
.grid {
  // Variables
  $_className: '.grid';
  $column-count: 12;

  // Styles
  @for $column from 1 through $column-count {
    // Generate columns
    &__col_#{$column} { // Генерирует классы-модификаторы вида .grid__col_1
      width: math.div($column * 100%, $column-count);
    }

    &__col_offset_#{$column} { // Генерирует классы-модификаторы вида .grid__col_offset_1
      margin-left: math.div($column * 100%, $column-count);
    }
  }
  @include mixins.micro-clearfix();

  & {
    text-align: left;
  }

  &__row {
    @include mixins.micro-clearfix();
  }

  &__col {
    float: left;
    padding: 4px;
  }

  // Модификатор отменяет внутренние отступы колонки
  &__col_no-spacing {
    padding: 0;
  }

  // Модификатор горизонтального выравнивания
  &__col_align_left {
    text-align: left;
  }

  &__col_align_right {
    text-align: right;
  }

  &__col_align_center {
    text-align: center;
  }

  // Модификатор делающий сетку таблицей
  &_as-table {
    display: table;
    width: 100%;
  }

  &_as-table #{$_className}__row {
    display: table-row;
    width: 100%;
  }

  &_as-table #{$_className}__row-group {
    display: table-row-group;
    width: 100%;
  }

  &_as-table #{$_className}__col {
    display: table-cell;
    float: none;
    vertical-align: middle;
  }
}
