@use '../settings/mixins';

.footer {

  // VARIABLES
  $block_margin: 5px;

  // STYLES
  @include mixins.center-column();

  & {
    height: 100%;
    overflow: hidden;
    font-weight: var(--font-weight_normal);
    color: #ffffff;

    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;
    align-content: stretch;
    align-items: center;
  }

  & a {
    color: #ffffff;
  }

  &__communication {
    padding: $block_margin;
    font-size: 15px;
    text-align: center;

    .icons-receiver {
      vertical-align: middle;
    }
  }

  &__communication-number {
    padding-right: 15px;
    padding-left: 10px;
    text-decoration: none;

    &--default {
      color: inherit;
    }
  }

  &__support {
    padding: $block_margin;
    font-size: 14px;
  }

  &__copyright {
    padding: $block_margin;
    font-size: 12px;
  }
}
