@use '../settings/variables';
@use '../settings/mixins';

.header {
  // Variables
  $logo-width: 115px;
  $auth-width: 64px;

  // Styles
  @include mixins.center-column();

  & {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  // Логотип компании
  &__logo {
    flex-shrink: 0;
    display: flex;
    align-items: center;

    &_white {
      background: #ffffff;
      max-height: var(--page_header_height);

      img {
        max-height: var(--page_header_height);
        padding: 0 3px;
        max-width: 99px;
      }
    }

    @media (max-width: variables.$device_sm-max) {
      border-right: 1px solid var(--page_bg-color);
    }
  }

  // Действия для пользователя
  &__actions {
    border-left: 1px solid var(--page_bg-color);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: variables.$device_md-min) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @media (min-width: variables.$device_lg-min) {
      padding-right: 0;
    }
  }

  // Действия с корзиной
  &__actions-cart {
    margin: 0 10px;
    display: inline-block;
  }

  // Действия текущего пользователя
  &__actions-user {
    margin: 0 10px;
    display: inline-block;
  }

  // Меню
  &__nav {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
  }
}

