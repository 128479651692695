@use '../settings/mixins';

.ls-select {
  // STYLES
  display: inline-flex;
  @include mixins.form-control();
}

.ngx-select {
  // VARIABLES
  $self-height: calc(var(--control_height) - 2px); // (-2) is amendment to the borders width
  $_className: '.ngx-select';

  flex-grow: 1;
  position: relative;
  min-height: $self-height;
  max-height: 100%;
  max-width: 100%;
  display: inline-flex;
  align-items: stretch;
  background: transparent; // #fff none;
  outline: none;

  &_multiple {
    padding: 1px 0 2px 2px;
    flex-direction: column;
  }

  &__toggle {
    width: 100%;
    line-height: 1.42857143;
    color: inherit;
    text-align: left;
    display: inline-block;
    padding: 0 8px;
    margin-bottom: 0;
    font-weight: var(--font-weight_normal);
    white-space: normal;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background: transparent; // #fff none;
    border-color: #ccc;
    height: 100%;
    min-height: $self-height; // hack for old browsers

    &:active:focus, &:focus {
      outline-offset: -2px;
    }

    &:focus {
      color: inherit;
      border-color: #8c8c8c;
    }

    &:hover {
      color: inherit;
      border-color: #adadad;
      background: transparent; // #fff none;
    }

    &:active {
      color: inherit;
      border-color: #adadad;
    }

    &:active:focus, &:active:hover {
      color: inherit;
      border-color: #8c8c8c;
    }

    // The vertical line beside the button
    &:after {
      content: '';
      width: 2px;
      position: absolute;
      top: 4px;
      bottom: 4px;
      right: 30px;
      border-left: 1px solid var(--control_valid_border-color);
    }
  }

  // The drop-down button
  &__toggle-caret {
    pointer-events: none;

    display: inline-block;
    width: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 5px var(--page_header_bg-color) solid;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    position: absolute;
    height: 10px;
    top: 50%;
    right: 10px;
    margin-top: -2px;
  }

  &__clear {
    padding: 0;
    height: 100%;
    margin-top: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__toggle #{$_className}__clear {
    width: 30px;
    position: absolute;
    top: 0;
    right: 22px;
  }

  &__clear-icon {
    @include mixins.cross(15px, var(--page_header_bg-color), var(--page_header_bg-color--darken_20), 3px);

    & {
      display: block;
    }
  }

  &_multiple #{$_className}__clear-icon {
    color: black;
  }

  &__selected-single, &__selected-plural {
    float: none;
    display: flex;
    padding-right: 27px;
    align-items: center;
    overflow: hidden;
  }

  &__selected-single {
    height: 100%;
    width: 100%;
  }

  &__selected-plural {
    @include mixins.form-control();

    & {
      text-align: left;
      display: inline-block;
      white-space: normal;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      user-select: none;
      color: inherit;
      background: #fff none;
      border-color: #ccc;
      font-size: 12px;
      line-height: 1.5;
      border-radius: 3px;
      position: relative;
      outline: 0;
      margin: 1px 3px 1px 0;
      padding: 5px;
    }

    &:active:focus, &:focus {
      outline-offset: -2px;
    }

    &:focus {
      color: inherit;
      border-color: #8c8c8c;
    }

    &:hover {
      color: inherit;
      border-color: #adadad;
    }

    &:active {
      color: inherit;
      border-color: #adadad;
    }

    &:active:focus, &:active:hover {
      color: inherit;
      border-color: #8c8c8c;
    }
  }

  &__allow-clear {
    padding-right: 50px;
  }

  &__placeholder {
    display: inline-flex;
    align-items: center;
    color: var(--text_base_color--lighten_25);
    height: 100%;
    padding-right: 30px;
    padding-top: 1px;
    font-size: var(--font-size__placeholder);
  }

  &__search, &__selected {
    display: block;
    width: 100%;
    border: none;
    margin: 0;
    min-height: 28px;

    // Hack for IE
    //min-height: $self-height - 2px;
  }

  &__search {
    padding: 0 8px 0 24px !important;
    background: var(--image_search) no-repeat 4px 50%;
    font-weight: var(--font-weight_normal);
    color: inherit;
  }

  &__choices {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;

    &.show {
      display: block;
    }
  }

  &__item-group {
    font-size: inherit;
  }

  &__item {
    color: inherit;
  }

  &__item:hover {
    text-decoration: none;
  }

  &__item_active {
    color: #fff;
  }
}
