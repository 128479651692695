@use '../../settings/variables';
@use '../../settings/mixins';
@use '../comment';

// Блок "информации о заказе" в карточке заказа
.order-info {
  $_className: '.order-info';
  // styles
  display: flex;
  justify-content: space-between;
  font-size: 0;
  width: 100%;
  min-height: var(--toolbar_height);
  color: var(--text_base_color);
  border-top: 1px solid var(--block_divider_color);
  border-bottom: 1px solid var(--block_divider_color);

  @media (max-width: variables.$device_sm-max) {
    flex-wrap: wrap;
  }

  & + #{$_className} {
    border-top: none;
  }

  &__panel {
    display: flex;
    align-items: center;
    font-size: 13px;
    text-align: center;

    @media (max-width: variables.$device_sm-max) {
      width: 100%;
      padding: var(--block_space) 0;
      &:not(:first-of-type) {
        border-top: 1px solid var(--block_divider_color);
      }
    }
    @media (min-width: variables.$device_md-min){
      &:first-of-type {
        padding-right: 4px;
      }
      &:not(:first-of-type):not(:last-of-type) {
        padding-left: 4px;
        padding-right: 4px;
      }
      &:last-of-type {
        padding-left: 4px;
        //text-align: right;
      }
    }
  }

  &__panel-divider {
    width: 1px;
    border-left: 1px solid var(--block_divider_color);
    @media (max-width: variables.$device_sm-max) {
      display: none;
    }
  }

  &__panel-comment {
    @extend .comment;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 15px;

    @media (max-width: variables.$device_sm-max) {
      padding: var(--block_space) 0;
    }
  }

  &__control {
    @include mixins.vertical-align-text(15px);
    display: inline-block;
  }

  &__strong {
    font-weight: var(--font-weight_bold);
  }

  &__strong_large {
    font-size: 15px;
  }

}
