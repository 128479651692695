@use "sass:color";
@use '../settings/mixins';

.button {
  // Mixins
  @mixin simple-button($top: 100%) {
    &:before, &:after {
      background-color: var(--page_header_bg-color);
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      top: $top;
      left: 0;
      margin-top: -1px;
    }

    &:hover:before, &:focus:before,
    &:hover:after, &:focus:after {
      background-color: var(--page_header_bg-color--darken_20);
    }
  }

  // Styles
  @include mixins.button-background-color(var(--page_header_bg-color), var(--page_header_bg-color--darken_10));

  & {
    transition: background-color 0.3s ease-out;
    position: relative;
    overflow: hidden;
    font-weight: var(--font-weight_bold);
    font-size: 18px;
    color: #ffffff;
    padding: 0 20px;
    cursor: pointer;
    text-align: center;
    height: 40px;
    text-decoration: none;
    border: none;

    //display: inline-block;
    //.vertical-align();
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  // Disabled button
  &:disabled {
    background-color: var(--control_disabled_bg-color);
    cursor: default;
  }

  // Модификатор - прижать в право
  &_right {
    float: right;
  }

  // Модификатор - кнопка панели инструментов
  &_tool {
    font-size: 15px;
    min-height: var(--toolbar_height);
    //height: 1px;
    height: 100%;
  }

  &_as-icon {
    padding-left: 5px;
    padding-right: 5px;
  }

  // Модификатор - тусклая кнопка
  &_dim {
    @include mixins.button-background-color(var(--control_disabled_bg-color), var(--page_header_bg-color--darken_10));
  }

  // Модификатор - растягивание кнопки во всю ширину
  &_full-width {
    padding: 0;
    width: 100%;
  }

  // Модификатор - кнопка крест
  &_close {
    @include mixins.button-background-color(transparent, color.adjust(transparent, $lightness: -10%));
    @include mixins.cross();

    & {
      margin-top: 3px;
    }
  }

  // Модификатор - кнопка галочка
  &_check {
    @include mixins.button-background-color(transparent, color.adjust(transparent, $lightness: -10%));
    @include simple-button(100%);

    & {
      width: 25px;
      height: 14px;
      padding: 0;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      margin-top: -5px;
      margin-left: 4px;
      transform: rotate(-50deg);
    }
  }

  // Модификатор - кнопка в виде картинки
  &_as-image {
    cursor: default;
    pointer-events: none;
  }
}
