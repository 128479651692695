@use '../settings/variables';
@use '../settings/mixins';

// Стилизация пагинатора angular: uib-pagination
// Документация к компоненту: https://ng-bootstrap.github.io/#/components/pagination
.pagination {
  // Variables
  $item-size: 33px;

  // Mixins
  @mixin item() {
    font-size: 16px;
    //display: inline-block;
    width: $item-size;
    height: $item-size;
    line-height: $item-size;
    margin: 0 1px;
    text-align: center;

    a {
      display: inline-block;
      color: var(--page_header_bg-color);
      text-decoration: none;
      width: 100%;
      height: 100%;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: var(--page_header_bg-color--darken_20);
      }
    }
  }

  // Styles
  display: flex;
  justify-content: center;
  align-items: stretch;
  font-weight: var(--font-weight_normal);
  //text-align: center;

  .page-item {
    @include item();
  }

  .page-item:nth-child(2) { // .pagination-prev
    @include item();
    a {
      @include mixins.arrow(-135deg);
      span {
        display: none;
      }
    }
    margin: 0 0 0 $item-size * 0.5;
  }

  .page-item:nth-last-child(2) { // .pagination-next
    @include item();
    a {
      @include mixins.arrow(45deg);
      span {
        display: none;
      }
    }
    margin: 0 $item-size * 0.5 0 0;
  }

  .page-item:first-child { //.pagination-first
    @include item();
    width: auto;
    a {
      &:before {
        content: 'Первая';
        display: inline;
      }
      span {
        display: none;
      }
    }
    @media (max-width: variables.$device_xs-max) {
      display: none;
    }
  }

  .page-item:last-child { // .pagination-last
    @include item();
    width: auto;
    a {
      &:before {
        content: 'Последняя';
        display: inline;
      }
      span {
        display: none;
      }
    }
    @media (max-width: variables.$device_xs-max) {
      display: none;
    }
  }

  .active {
    a {
      background-color: var(--page_header_bg-color);
      color: #ffffff;
      &:hover {
        color: #ffffff;
      }
    }
  }

  .disabled {
    a {
      color: var(--control_disabled_bg-color) !important;
      &:hover {
        color: var(--control_disabled_bg-color);
        text-decoration: none !important;
        cursor: default;
      }
    }
  }
}
