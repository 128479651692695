@use "sass:color";
@use '../settings/mixins';

.ls-flow-panel {
  $_className: '.ls-flow-panel';

  // Стили блока
  position: relative;
  outline: none;

  &__triangle {
    width: 0;
    height: 0;
    border-bottom: 9px solid #ffffff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    display: block;
    position: absolute;
    right: 7px;
    top: -9px;
  }

  &__title {
    margin-right: var(--block_space);
  }

  &__close {
    @include mixins.button-background-color(transparent, color.adjust(transparent, $lightness: -10%));
    @include mixins.cross(15px);

    & {
      margin-top: 3px;
    }
  }

  &__panel {
    padding: 18px;

    white-space: nowrap;
    position: absolute;
    z-index: 1002;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(179, 179, 178, 0.92);
    right: 0;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    transition: 0s visibility .4s, opacity .3s ease, top .3s ease;
  }

  &_auto:focus > #{$_className}__panel, &_open > #{$_className}__panel {
    top: 50px;
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity .3s ease, top .3s ease;
  }

  &_frame #{$_className}__panel {
    border: 1px solid var(--page_header_bg-color);
    padding: var(--block_space--double);
  }

  &_frame #{$_className}__triangle {
    border-bottom-color: var(--page_header_bg-color);
  }
}
