@use '../settings/mixins';

.form2 {
  // Variables
  $_className: '.form2';
  $font-size: 15px;
  $vertical-padding: 7px;
  $textarea-height: 80px;

  $font-size_alert: 14px;

  // Styles
  font-size: $font-size;
  color: var(--text_base_color);
  font-weight: var(--font-weight_normal);
  max-width: 100%;

  // Модификатор ширины формы
  &_width_medium {
    width: 350px;
  }

  &_width_large {
    width: 430px;
  }

  &_width_large920 {
    width: 920px;
  }

  &_width_large990 {
    width: 990px;
  }

  // Заголовок раздела/набора полей фомы
  &__header {
    padding-left: 10px;
    font-size: 17px;
    font-weight: var(--font-weight_normal);
    color: var(--page_header_bg-color);
    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  &__item-base {
    color: var(--text_base_color);
    display: block;
    width: 100%;
  }

  // Базовый элемент формы
  &__item {
    color: var(--text_base_color);
    display: block;
    width: 100%;
    min-height: var(--control_height);
    padding-top: $vertical-padding;
    padding-bottom: $vertical-padding;
    border: 1px solid transparent;
  }

  &__item_simple {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 0;
  }

  &__item_align_center {
    text-align: center;
  }

  // Цвет текста элемента
  &__item_green {
    color: var(--text_success_color);
  }

  &__item_red {
    color: var(--text_alert_color);
  }

  // Делает элемент строчным
  &__item_inline {
    display: inline-block;
    width: auto;

    &:not(:last-of-type) {
      padding-right: 5px;
    }
  }

  // Элемент - метка
  &__item_label {
    text-align: right;
    padding-right: 5px;
  }

  &__item_label-left {
    text-align: left;
  }

  // @deprecated Элемент - поле ввода
  &__item_input {
    @include mixins.form-control();

    & {
      padding-left: 8px;
      padding-right: 8px;
    }

    @at-root {
      textarea#{&} {
        height: $textarea-height;
      }
    }
  }

  // Элемент - фиксированный текст
  &__item_text {
    font-weight: var(--font-weight_bold);
  }

  // Элемент - ссылка
  &__item_link {
    text-decoration: none;
    font-weight: var(--font-weight_bold);

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  // Элемент - ссылка-действие. От основной отличается меньшим размером и видом
  &__item_link-action {
    padding: 0;
    font-weight: var(--font-weight_normal);
    text-decoration: underline;
    font-size: 13px;
    min-height: 0;
    cursor: pointer;
  }

  // Важное текстовое сообщение
  &__alert {
    font-size: 14px;
    text-align: center;
    color: var(--text_alert_color);
  }

  // Модификатор форм в таблице
  &_in-table #{$_className}__item_label {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 0;
  }
}
