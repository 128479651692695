// Сумма в заказе
.order-sum {
  $_className: '.order-sum';
  //.vertical-align-text(22px);
  font-size: 15px;
  color: var(--text_base_color--lighten_20);
  min-height: var(--toolbar_height);
  display: inline-flex;

  // Модификатор - итоговая сумма
  &_total {
    color: var(--text_base_color);
  }

  // Числовое значение
  &_total #{$_className}__number {
    font-size: 20px;
  }

  &_total #{$_className}__number_large {
    font-size: 22px;
  }

  // Числовое значение
  &__number {
    padding: 0 4px;
    &_red{
      color: #e44108;
    }
  }

  // Поле ввода
  &__input {
    height: var(--toolbar_height);
    width: 40px;
    text-align: center;
    margin: 0 4px;
  }

  // Ссылка
  &__link {
    margin: 0 4px;
    cursor: pointer;
    border-bottom: 1px dashed var(--text_base_color);
    display: inline-flex;
    align-items: center;
  }
}
