@use '../../settings/mixins';

// Блок "добавить позицию" в редактировании заказа
.order-add-position {
  // Variables
  $order-control_height: 32px;
  $triptych-left_width: 160px;
  $triptych-right_width: 280px;

  // Styles
  background-color: var(--table_active-row_bg-color);
  color: var(--text_base_color);
  padding-top: 5px;
  padding-bottom: 5px;
  height: $order-control_height + 12px;

  &__triptych-left {
    @include mixins.vertical-align();

    & {
      float: left;
      height: 100%;
      width: $triptych-left_width;
    }
  }

  &__triptych-right {
    @include mixins.vertical-align();

    & {
      float: right;
      padding-left: 10px;
      width: $triptych-right_width;
    }
  }

  &__triptych-center {
    margin-left: $triptych-left_width;
    margin-right: $triptych-right_width;
    height: 100%;
  }

  &__control {
    @include mixins.form-control();

    & {
      height: $order-control_height;
    }
  }

  &__control_name {
    width: 100%;
  }

  &__control_count {
    margin-left: 10px;
    width: 70px;
    text-align: center;
  }

  &__button {
    height: $order-control_height;
  }
}
