.ls-documentation {
  margin: var(--block_space--double) var(--block_space);

  &__title {
    font-size: 1.2rem;
    font-weight: var(--font-weight_bold);
  }

  &__subtitle {
    margin-top: var(--block_space--double);
    font-weight: var(--font-weight_bold);
    border-bottom: 1px dashed var(--block_divider_color);
  }

  &__description, &__parameters, &__examples {
    margin: var(--block_space) 0;
  }
}
