@use 'sass:map';
@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();

// Typography
$fontConfig: mat.m2-define-typography-config(
        $caption: mat.m2-define-typography-level(12px, 20px, 300, 'Roboto', inherit), // input hint
        $body-1: mat.m2-define-typography-level(inherit, 1.125, 300, 'Roboto', inherit), // input
);

// Palette
// See: https://m2.material.io/inline-tools/color/
$mat-primary: map.deep-merge(mat.$m2-blue-palette, (
        500: var(--page_header_bg-color),
));
$theme-primary-palette: mat.m2-define-palette($mat-primary, 500);
$theme-accent-palette: mat.m2-define-palette($mat-primary, 500);

// Theme
$theme: mat.m2-define-light-theme((
        color: (
                primary: $theme-primary-palette,
                accent: $theme-accent-palette,
        ),
        typography: $fontConfig,
));

// Setup
@include mat.all-component-typographies($fontConfig);
@include mat.all-component-themes($theme);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Angular v15. MatList FIX
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  color: white !important;
}

.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: #d4d4db !important;
  border-width: 1px;
}

.mdc-list-item:focus::before {
  background-color: rgba(89,88,86,.4) !important;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Angular v15. MatField FIX
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.mdc-text-field--filled:not(.mdc-text-field--disabled), .mat-mdc-form-field-focus-overlay {
  background-color: white;
}

.mdc-text-field, .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mdc-text-field .mdc-text-field__input {
  margin-top: 7.5px;
}

.mdc-text-field .mdc-floating-label {
  transform: translateY(0) !important;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-1.34375em) scale(0.95) !important;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) mdc-floating-label {
  color: var(--page_header_bg-color);
}
