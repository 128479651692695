@use '../settings/mixins';

// Область вывода контента страницы
.ls-content {
  // Variables
  $content_padding: 2%;

  // Styles
  @include mixins.center-column();

  & {
    background-color: #ffffff;
    margin-bottom: 23px;
    position: relative;
    font-weight: var(--font-weight_normal);
  }

  &:before, &:after {
    // Реализация отступа сверху и снизу в процентах от собственной ширины
    content: '';
    display: block;
    padding: $content_padding 0 0;
  }

  // Секция
  &__section {
    padding-left: $content_padding;
    padding-right: $content_padding;
  }

  // Секция с подсвеченным фоном
  &__section_active {
    background-color: var(--table_active-row_bg-color);
  }

  // Секция с заголовком страницы
  &__section_header {
    &:not(:first-of-type) {
      margin-top: 12px;
    }
  }

  // Секция для вывода пагинатора
  &__pagination {
    width: 100%;
  }
}
