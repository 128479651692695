.comment {
  font-family: 'Georgia', serif;
  font-style: italic;
  color: var(--page_header_bg-color);
  text-align: center;
  max-width: 100%;
  word-wrap: break-word;

  &_compact {
    color: var(--text_base_color);
    font-size: 13px;
    font-weight: var(--font-weight_bold);
  }
}
