@use "sass:math";
@use '../settings/variables';
@use '../settings/mixins';

// Блок вывода виджета вида "название параметра: значение параметра"
.ls-widget {
  // VARIABLES
  $_className: '.ls-widget';
  $vertical-padding: 7px;

  // STYLES
  width: 100%;
  padding: 4px 0;
  color: var(--text_base_color);
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  // Область для вывода названия параметра
  &__name {
    padding: var(--block_space);
    flex-grow: 4;
    flex-basis: math.div(100% * 4, 12);
    max-width: 250px;
    text-align: right;

    & > label {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      border: 1px solid transparent; // Компенсацию толщины границ input-ов

      &:before, &:after {
        content: '\200B';
        font-size: 0;
        display: block;
        max-height: $vertical-padding;
        flex-grow: 1;
      }
    }
  }

  // Область вывода значения параметра
  &__value {
    padding: var(--block_space);
    flex-grow: 8;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    & > * {
      flex-grow: 1;
      max-width: 100%;
      width: 100%;
    }

    & > input, & > textarea {
      @include mixins.form-control();

      & {
        padding: $vertical-padding 8px;
        min-height: var(--control_height);
      }
    }

    //& > textarea {
    //  height: 34px;
    //}
  }

  &__name + #{$_className}__value {
    flex-basis: math.div(100% * 8, 12);
    //max-width: 100% * 8/12;
  }

  // "Пружина" расталкивает элементы в стороны стараясь занять все доступное пространство
  &__spring {
    flex-grow: 100;
  }

  // Элементы ссылка
  &__link, & a {
    text-decoration: none;
    color: inherit;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  // Модификатор соотношения ширины областей названия и значения
  &_ratio_5-7 #{$_className}__name {
    flex-grow: 5;
    flex-basis: math.div(100% * 5, 12);
    //max-width: 100% * 5/12;
  }

  &_ratio_5-7 #{$_className}__value {
    flex-grow: 7;
  }

  &_ratio_5-7 #{$_className}__name + #{$_className}__value {
    flex-basis: math.div(100% * 7, 12);
    //max-width: 100% * 7/12;
  }

  &_ratio_6-6 #{$_className}__name, &_ratio_6-6 #{$_className}__value {
    flex-grow: 6;
  }

  &_ratio_6-6 #{$_className}__name,
  &_ratio_6-6 #{$_className}__name + #{$_className}__value {
    flex-basis: math.div(100% * 6, 12);
    //max-width: 100% * 6/12;
  }

  // Модификатор вертикального расположения содержимого виджета
  &_vertical {
    display: block;
  }

  &_vertical #{$_className}__name {
    text-align: left;
  }

  &_vertical #{$_className}__name, &_vertical #{$_className}__value {
    width: 100%;
    max-width: none;
    display: block;
  }

  // Модификатор вертикального выравнивания виджета при очень маленьком экране
  @media (max-width: variables.$device_xs-max) {
    &_compact_xs #{$_className}__name {
      flex-grow: 0;
      flex-basis: auto;
    }

    &_vertical_xs {
      display: block;
    }

    &_vertical_xs #{$_className}__name {
      text-align: left;
    }

    &_vertical_xs #{$_className}__name, &_vertical_xs #{$_className}__value {
      width: 100%;
      max-width: none;
      display: block;
    }
  }
}
