@use "sass:color";

/*
 * @deprecated use component <app-info-button>
 */
.tooltip {
  z-index: 10;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 50%;
  left: calc(100% + 10px);
  padding: 8px 16px;
  transform: translateY(-50%);
  overflow: auto;
  width: 300px;
  max-height: 500px;
  text-align: left;
  font-size: 9pt;
  color: var(--text_base_color);
  background-color: var(--tooltip_bg-color);
  border-radius: 1px;
  border: 1px solid var(--tooltip_bg-color--darken_40);
  box-shadow: 1px 3px 4px -1px rgba(0, 0, 0, .4);
  transition: visibility 0.2s, opacity 0.1s linear;

  &-container {
    position: relative;

    outline: none;

    &:focus-within > .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }

  &__left {
    right: calc(100% + 10px);
    left: initial;
  }

  &__bottom {
    right: 0;
    left: initial;
    top: calc(100% + 10px);
    z-index: 15;
  }

  &__white {
    background-color: #FFF;
    border: 1px solid color.adjust(#FFF, $lightness: -40%);
  }

}
