// Размеры устройств, для адаптивного диайна
$device_sm: 450px;   //768 Малые устройства
$device_md: 800px;   //992 Средние устройства
$device_lg: 1200px;  //1200 Большие устройства

$device_sm-min: $device_sm;
$device_md-min: $device_md;
$device_lg-min: $device_lg;

$device_xs-max: ($device_sm-min - 1);
$device_sm-max: ($device_md-min - 1);
$device_md-max: ($device_lg-min - 1);
