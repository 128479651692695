@use "sass:color";

// Theme 'green'
.theme-green {

  // Page
  --page_header_bg-color: #8abe7a;
  --page_header_bg-color--darken_5: #{color.adjust(#8abe7a, $lightness: -5%)};
  --page_header_bg-color--darken_10: #{color.adjust(#8abe7a, $lightness: -10%)};
  --page_header_bg-color--darken_15: #{color.adjust(#8abe7a, $lightness: -15%)};
  --page_header_bg-color--darken_20: #{color.adjust(#8abe7a, $lightness: -20%)};
  --page_header_bg-color--lighten_10: #{color.adjust(#8abe7a, $lightness: 10%)};

  // Main menu
  --menu-top_active-link_color: #72a05b;
  --menu-top_sub-link_color: #467139;
  --menu-top_active-icon_color: #83b873;

  // Menu panel
  --menu-panel_link_color: #467139;

  // User menu
  --user-menu_icon-man_bg-color: #b6de99;

  // Tables
  --table_header_color: #467139;

  --table_header_bg-color: #dfefda;
  --table_header_bg-color--darken_5: #{color.adjust(#dfefda, $lightness: -5%)};
  --table_header_bg-color--darken_8: #{color.adjust(#dfefda, $lightness: -8%)};
  --table_header_bg-color--lighten_5: #{color.adjust(#dfefda, $lightness: 5%)};

  --table_border_color: #8fc27f;
  --table_active-row_bg-color: #{color.adjust(#dfefda, $lightness: 7%)};
  --table_selected-row_bg-color: #{color.adjust(#dfefda, $lightness: 3%)};

  // Controls - check-box
  --checkbox_checked_image: url('/assets/checkbox_checked-green.png');
  --checkbox_indeterminate_image: url('/assets/checkbox_indeterminate-green.png');

  // Control - radio-button
  --radio_checked_image: url('/assets/radio_checked-green.png');
}
