@use "sass:color";
@use '../settings/mixins';

$black_url-color: #595856;

.url {
  color: var(--page_header_bg-color);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--page_header_bg-color--darken_15);
  }

  &_small {
    font-size: 13px;
  }
  &_middle {
    font-size: 15px;
  }

  &_space_right{
    margin-right: 5px;
  }

  &_strong {
    font-weight: var(--font-weight_bold);
  }

  &__prev {
    @include mixins.arrow(-135deg, 15px);
  }

  &__next {
    @include mixins.arrow(45deg, 15px);
  }

  &--black {
    color: $black_url-color;
    text-decoration: none;

    &:hover {
      color: color.adjust($black_url-color, $lightness: -15%);
    }
  }
}
