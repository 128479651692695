.ls-order-search {
  // VARIABLES
  $_className: '.ls-order-search';

  // STYLES
  background-color: white;
  font-size: 1rem;

  th {
    color: var(--text_base_color);
    text-align: center;
    padding: 0 3px 0;
    margin: 0;
    overflow: inherit;
  }

  &_active#{$_className}_status {
    &_1 {
      background-color: #f3f9f6; //#ebfef4;
    }
    &_2 {
      background-color: #f8e9f0;
    }
    &_10 {
      background-color: #efffe5;
    }
    &_11 {
      background-color: white;
    }
  }
}
