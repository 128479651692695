@use "sass:color";
@use '../settings/variables';

$_canvas_color: #F8F8F8;
.ls-profile {

  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/

  position: relative;

  canvas {
    border: 1px solid color.adjust($_canvas_color, $lightness: -20%);
  }

  @media (max-width: variables.$device_xs-max) {
    display: block;
  }

  &__error {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_2d {
    canvas {
      cursor: none;
      padding-bottom: 4px;
    }

    .controls {
      position: absolute;
      bottom: 3px;
      left: 4px;
    }

    .editMode {
      cursor: pointer;
    }
  }

  &_3d {
    cursor: none;

    .controls {
      position: absolute;
      bottom: 3px;
      left: 4px;

      .btn {
        cursor: pointer;
        display: inline-block;
        width: 35px;
        height: 35px;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }

        &_zoom_in {
          background: url('/assets/plus.png');
          background-size: contain;
        }

        &_zoom_out {
          background: url('/assets/minus.png');
          background-size: contain;
        }

        &_rotate {
          background: url('/assets/auto-rotate.png');
          background-size: contain;
        }
      }
    }

    canvas {
      cursor: pointer;
    }
  }

  &_print {
    //margin: -2mm;
    canvas {
      border: none;
    }
  }

  &__image {
    display: flex;
    width: 100%;
    max-height: 100%;
  }

  &__image_small svg {
    height: 100px;
  }

  .rot {
    background: (url('/assets/rotate.png'));
    width: 24px;
    height: 24px;

    &:active {
      background-color: #cecdcd;
    }

    &:hover {
      box-shadow: 0 0 3px #676767
    }

    &_l {
      transform: scale(-1, 1);
    }

    &_r {

    }
  }

  .editForm {
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    width: 160px;
    height: auto;
    border: solid 1px #888;
    box-shadow: 4px 4px 9px #777;
    z-index: 11;
    font-size: 10pt;
    padding: 3px;

    .ls-widget__name {
      flex-basis: 50%;
    }

    .ls-widget__value {
      padding: 0;
      flex-basis: 50%;

      input {
        text-align: center;
        padding: 4px;
        min-height: inherit;
      }
    }

    .ls-block__group {
      margin: auto;

      .ls-block__item-control {
        input.radio-button {
          + label {
            height: inherit;
            font-size: inherit;
            line-height: 13px;
            stroke: black;
          }

          &:checked + label {
            stroke: white;
          }
        }

        app-svg-icon-button svg {
          margin: 0;
          width: 35px;
          height: 27px;
        }
      }
    }
  }

  .button {
    height: 27px;
  }

  .ls-widget__name {
    flex-basis: 64%;
  }

  .ls-widget__value {
    line-height: 25px !important;

    input.link {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    input[type=radio] {
      min-height: 20px;
    }

    textarea {
      height: 86px;
    }

    button {
      height: 34px;
      width: 50%;
    }
  }
}
