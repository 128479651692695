@use '../settings/mixins';
@use './ls-util';

.ls-datepicker2 {
  @extend .lsu-control;

  height: var(--control_height);
  display: flex;
  align-items: center;

  &__input {
    font: inherit;
    border: none;
    width: 100%;
    color: inherit;
  }

  &__button-block {
    display: flex;
    margin: 0;
  }

  &__button {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #E6E6E6;
    }
  }

  &__button-clear {
    @include mixins.cross(15px, var(--page_header_bg-color), var(--page_header_bg-color--darken_20), 3px);
  }

  // Style of NgbDatepicker
  .dropdown-menu {
    background-color: white;
    z-index: 100;
  }

  .custom-select {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0 .5rem;
    font-size: .875rem;
    height: 1.85rem;

    display: inline-block;
    width: 100%;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center/8px 10px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .ngb-dp-header {
    border-bottom: 0;
    border-radius: .25rem .25rem 0 0;
    padding-top: .25rem;
    background-color: #f8f9fa;
  }

  .bg-primary {
    background-color: #007bff !important;
  }

  .text-white {
    color: #fff !important;
  }

  .text-muted {
    color: #6c757d !important;
  }

  .ngb-dp-weekdays {
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0;
    background-color: #f8f9fa;
  }

  .ngb-dp-weekday {
    color: #17a2b8;
  }

  .ngb-dp-week-number, .ngb-dp-weekday {
    line-height: 2rem;
    text-align: center;
    font-style: italic;
  }

  .small {
    font-size: 80%;
    font-weight: 400;
  }

  .m-2 {
    margin: .5rem !important;
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  .btn:hover {
    color: #212529;
    text-decoration: none;
  }

  .btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
  }

  .btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }

  .btn-light.focus, .btn-light:focus, .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }

  .btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }

  .btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }

  .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }

  .btn-secondary.focus, .btn-secondary:focus, .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
  }

  .btn-group-sm > .btn, .btn-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
  }

  .float-left {
    float: left !important;
  }

  .float-right {
    float: right !important;
  }

  .mb-0, .my-0 {
    margin-bottom: 0 !important;
  }

  .mt-0, .my-0 {
    margin-top: 0 !important;
  }

  [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
  }

  [ngbDatepickerDayView] {
    text-align: center;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: .25rem;
    background: transparent;
  }

  .ngb-dp-arrow.right {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .ngb-dp-arrow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding-right: 0;
    padding-left: 0;
    margin: 0;
    width: 2rem;
    height: 2rem;
  }

  .ngb-dp-arrow-btn {
    padding: 0 .25rem;
    margin: 0 .5rem;
    border: none;
    background-color: transparent;
    z-index: 1;
  }
}
