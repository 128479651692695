.ls-profile-catalog {
  .info {
    color: var(--page_header_bg-color);
    font-size: 21px;
    font-style: italic;
    margin: 15px;
    text-align: center;
  }

  .ls-tabs {
    margin-bottom: -1px;

    label:first-of-type {
      border-left: 1px solid var(--page_header_bg-color);
      border-top-left-radius: 4px;
    }

    label:last-of-type {
      border-right: 1px solid var(--page_header_bg-color);
      border-top-right-radius: 4px;
    }

    &__button:checked + label {
      border-bottom: none;
      background-color: white;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid var(--page_header_bg-color);
    padding: 5px
  }

  .profile__item {
    width: 216px;
    height: auto;
    border: 1px solid var(--page_header_bg-color);
    margin: 5px;
    padding: 0;
    border-radius: 3px;
    background-color: var(--page_header_bg-color);
    cursor: pointer;
    color: white;
    position: relative;

    &:hover {
      box-shadow: 2px -2px 5px 0px rgba(88, 88, 88, 0.75);
      border-color: #525252;
    }

    .icons {
      position: absolute;
      top: 11px;
      right: 5px;
    }

    .form2__item_label {
      color: white;
      //white-space: nowrap;
      overflow: hidden;
      //text-overflow: ellipsis;
      padding: 0 5px;
      font-size: 13px;
    }
  }
}
