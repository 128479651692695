.icon {
  background: url('/assets/icons.png') no-repeat;
  display: inline-block;
  vertical-align: middle;

  &_space_right {
    margin-right: 10px;
  }

  &_accept {
    background-position: 0 0;
    height: 13px;
    width: 14px;
  }

  &_exit {
    background-position: 0 -26px;
    height: 16px;
    width: 23px;
  }

  &_receiver {
    background-position: 0 -42px;
    height: 19px;
    width: 17px;
  }

  &_stop {
    background-position: 0 -13px;
    height: 13px;
    width: 14px;
  }
}
