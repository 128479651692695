@use '../settings/variables';

/*
Блок сортировки.
Отвечает за показ иконки сортировки.
*/
.ls-filter {
  $_className: '.ls-filter';
  // Variables
  $icon-size: 10px;
  $icon-rounded-padding: 3px;
  $icon-rounded-size: $icon-size + $icon-rounded-padding * 2 + 1;

  // Styles
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  outline: none;
  min-height: var(--table_header_height);

  // Text link
  &__link {
    border-bottom: 1px dashed transparent;
    white-space: normal;
    //word-wrap: break-word;
    //word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover #{$_className}__link {
    border-bottom-color: inherit;
  }

  // Icon
  &__icon {
    flex-shrink: 0;
    vertical-align: middle;
    width: $icon-size;
    height: $icon-size;
    fill: var(--page_header_bg-color);

    @media (min-width: variables.$device_md-min) {
      margin-left: 5px;
    }
  }

  &:hover #{$_className}__icon {
    fill: var(--page_header_bg-color--darken_10);
  }

  // Active icon
  &__icon_active {
    fill: #ffffff;
    background-color: var(--page_header_bg-color);
    width: $icon-rounded-size;
    height: $icon-rounded-size;
    padding: $icon-rounded-padding;
    border-radius: 0.5 * ($icon-rounded-size);
  }

  &:hover #{$_className}__icon_active {
    fill: #ffffff;
    background-color: var(--page_header_bg-color--darken_10);
  }

  &__menu {
    position: absolute;
    background: white;
    padding: var(--block_space);
    cursor: auto;
    top: calc(100% + 8px);
    right: 0;
    z-index: 10;
    max-height: calc(100vh - 230px);
    overflow: auto;
  }

  &_auto #{$_className}__menu {
    display: none;
  }

  &_auto:focus #{$_className}__menu {
    display: block;
  }
}
