@use '../settings/variables';

// Блок является "телом" для всего контента документа
.body-wrapper {
  height: 100%;
  min-height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  @media print {
    // Во время печати ставим обычный блок, что бы избежать проблем с печатью.
    // FireFox не поддерживает разрыв flex-блока при печати.
    display: block;
  }

  // Область шапки
  &__header {
    flex-shrink: 0;
    min-height: var(--page_header_height);
    background-color: var(--page_header_bg-color);

    display: flex;
    justify-content: center;
    align-items: stretch;
  }

  // Область тела документа
  &__body {
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
  }

  // Область подвала
  &__footer {
    flex-shrink: 0;
    min-height: var(--page_footer_height);
    background-color: var(--page_footer_bg-color);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  // Модификатор. Тело с плавающим контентом. Контент центрируется
  &__body_float {
    display: flex;
    background-color: white;
    justify-content: space-around;
    align-items: center;

    & > * {
      margin: auto; // Fix for IE
    }

    @media (min-width: variables.$device_md-min) {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
