@use "sass:color";
@use '../settings/variables';

.menu-top {
  $_className: '.menu-top';
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;

  @media (min-width: variables.$device_sm-min) {
    justify-content: center;
  }

  // Level 1
  &__item {
    position: relative;
    font-size: 16px;
    display: flex;
    align-items: center;
    outline: none;

    &:hover {
      transition-duration: 0.5s;
      background-color: var(--page_bg-color);
    }

    @media (min-width: variables.$device_md-min) {
      border-left: 1px solid var(--page_bg-color);
      &:last-of-type {
        border-right: 1px solid var(--page_bg-color);
      }
    }
  }

  &__item-link {
    color: #ffffff;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;

    display: flex;
    align-items: center;

    @media (min-width: variables.$device_md-min) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  &__item-icon {
    fill: #fff;
    height: 25px;
    width: 25px;
  }

  &__item-text {
    margin-left: 10px;

    @media (max-width: variables.$device_xs-max) {
      display: none;
    }
  }

  // Level 1 - Модификатор
  &__item_active {
    background-color: #ffffff;
  }

  &__item:hover #{$_className}__item-icon,
  &__item_active #{$_className}__item-icon {
    fill: var(--menu-top_active-icon_color);
  }

  &__item:hover #{$_className}__item-link,
  &__item_active #{$_className}__item-link {
    color: var(--menu-top_active-link_color);
  }

  // Level 2
  &__sub-menu {
    display: none;
    top: 100%;
    left: 0;
    z-index: 10;
    min-width: 100%;
  }

  &__sub-item {
    position: relative;
    display: block;
    padding: 0 20px;
    background-color: var(--page_bg-color);
    white-space: nowrap;

    &:hover {
      transition-duration: 0.5s;
      background-color: var(--page_header_bg-color);
    }
  }

  &__sub-item:hover > #{$_className}__sub-link {
    color: #ffffff;
  }

  &__sub-link {
    font-size: 14px;
    padding: 10px 0;
    min-width: 90px;
    margin-top: 0;
    display: block;
    text-align: left;
    text-decoration: none;
    color: var(--menu-top_sub-link_color);
  }

  &__item:hover > #{$_className}__sub-menu, &__sub-menu:hover {
    display: block;
    position: absolute;
    transition-duration: 0.5s;
  }

  &__sub-item:hover > #{$_className}__sub-menu {
    display: block;
    left: 100%;
    top: 0;
    position: absolute;
  }

  // Level 2 - Модификатор
  &__sub-item_active {
    background-color: var(--page_header_bg-color--lighten_10);
  }

  &__sub-item_active > #{$_className}__sub-link {
    color: color.adjust(#ffffff, $lightness: -10%);
  }
}
