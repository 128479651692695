// Remove default fieldset styles.
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

input {
  outline: none;
  &::placeholder {
    color: #999895;
    font-size: var(--font-size__placeholder);
    vertical-align: baseline;
  }
}

textarea {
  resize: vertical;
  vertical-align: top;
}

textarea:focus, input:focus {
  outline: none;
}

a {
  outline: none;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 0;
}

li {
  font-size: 16px;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
