@use '../../settings/mixins';

.order-comment {
  display: block;
  width: 100%;
  color: var(--text_base_color);
  text-align: center;

  &__input {
    @include mixins.form-control();
    //height: 100%;
    & {
      height: var(--control_height);
      display: block;
      width: 100%;
      padding: 0 10px;
    }
  }
}
