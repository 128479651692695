// Fix Angular Material Dialog styles
.cdk-overlay-container {
  // todo: delete it when deleted the legacy modal window
  z-index: 1100; // in order to overlap the legacy modal window

  .cdk-global-overlay-wrapper {
    overflow: auto;
    pointer-events: auto; // needed for chrome but prevents backdrop click close

    .cdk-overlay-pane {
      max-width: 100% !important;

      mat-dialog-container {
        border-radius: 0;
        overflow: initial;
        padding: 0;

        .mat-mdc-dialog-surface {
          border-radius: 0; // Fix MaterialDialog v15
        }

        mat-dialog-content {
          font-family: Roboto,sans-serif; // Fix MaterialDialog v15
          font-weight: var(--font-weight_normal); // Fix MaterialDialog v15
          font-size: inherit; // Fix MaterialDialog v15
          letter-spacing: normal; // Fix MaterialDialog v15
          color: inherit; // Fix MaterialDialog v15
          line-height: inherit; // Fix MaterialDialog v15
          margin: 0;
          padding: 0;
          max-height: initial;
        }

        mat-dialog-actions {
          margin-bottom: 0;
          padding: 0;
          min-height: initial;
          border-top: none; // Fix MaterialDialog v15
          justify-content: center; // Fix MaterialDialog v15
        }
      }
    }
  }
}
