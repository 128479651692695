.ls-page-print-specification {
  // PARAMETERS
  $table-cell_padding: 1.5mm;
  $table_border-width: 1px;

  // STYLES
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 9pt;

  @media screen {
    padding: 20mm 15mm 20mm 20mm;
  }

  &__table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;

    thead th, tbody td {
      padding: $table-cell_padding;
      border: 1px solid black;
    }
  }
}