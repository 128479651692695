@use "sass:color";
@use '../settings/mixins';

// Оформление "печатной площадки"
.ls-print-preview {
  $_className: '.ls-print-preview';
  // VARIABLES
  $print-menu_bg-color: var(--text_success_color);
  $print-menu_height: 45px;
  $print-page_v-margin: 2vw;
  $print-page_shadow: 0 0 $print-page_v-margin 0 #222;

  // STYLES
  margin: 0;
  padding: 0;
  font-size: 8pt;

  @media print {
    background-color: white;
  }

  // Меню с кнопками для печати
  &__menu {
    @include mixins.vertical-align();

    & {
      height: $print-menu_height;
      background-color: $print-menu_bg-color;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 100;
      padding: 0 20px;
    }

    @media print {
      display: none;
    }
  }

  // Элемент управления в меню
  &__menu-control {
    height: 80%;
    font-size: 16px;
    padding: 0 1vw;
    display: inline-block;
  }

  &__menu-text {
    height: 80%;
    font-size: 16px;
    padding: 0 1vw;
    display: inline-flex;
    align-items: center;
    color: white;
  }

  &__menu-close {
    @include mixins.cross(25px, white, color.adjust(white, $lightness: -20%), 2px);

    & {
      float: right;
      height: 100%;
      padding: 0 1vw;
    }
  }

  // Контейнер страниц
  &__view {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    @media print {
      display: block;
    }
  }

  &__menu + #{$_className}__view {
    margin-top: $print-menu_height;

    @media print {
      margin: 0 !important;
    }
  }

  // Страница печати
  &__page {
    font-size: 8pt;
    overflow: hidden;
    page-break-inside: avoid;
    page-break-before: always; // fix "sticker-pickup-address": https://lisa10.planfix.ru/task/19193
    //page-break-after: always; // the reason for printing blank sticker
    background-color: white;
    padding: 0;

    @media screen {
      box-shadow: $print-page_shadow;
    }

    @media print {
      width: auto !important;
      height: auto !important;
      min-height: 0 !important;
    }
  }

  &__page_print-rotate_90 {
    @media print {
      transform: rotate(90deg);
    }
  }

  &__page_print-rotate_180 {
    @media print {
      // При печати поворачиваем этикетки на 180 градусов,
      //что бы лента этикеток выглядела нормально и не было путанницы.
      transform: rotate(180deg);
    }
  }

  &__page_print-rotate_270 {
    @media print {
      transform: rotate(270deg);
    }
  }

  // Модификатор - страница формата "стикер"
  &__page_sticker {
    display: flex;
    width: var(--print-page-sticker_width);
    height: var(--print-page-sticker_height);

    /**
    * Force browsers to print background images in CSS
    * @see https://stackoverflow.com/questions/6670151/how-can-i-force-browsers-to-print-background-images-in-css
    */
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important; /* Firefox 48 – 96 */
    print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
  }

  &_orientation_landscape #{$_className}__page_sticker {
    width: var(--print-page-sticker_height);
    height: var(--print-page-sticker_width);
  }

  // Модификатор - страница фармата A4
  &__page_a4 {
    width: var(--print-page-A4_width);
    min-height: var(--print-page-A4_height);
  }

  &_orientation_landscape #{$_className}__page_a4 {
    width: var(--print-page-A4_height);
    min-height: var(--print-page-A4_width);
  }

  // Декоративное оформление страницы
  &__page-decorator {
    @media screen {
      display: flex;
      margin: $print-page_v-margin auto 0;
      &:last-of-type {
        margin-bottom: $print-page_v-margin;
      }

      &:before, &:after {
        content: '';
        flex-shrink: 0;
        width: $print-page_v-margin;
      }
    }
  }

  // Модификатор - черная печатная страница
  &__page_black {
    background-color: black;
    color: white;
  }
}
