@use "sass:color";
@use '../settings/variables';

$_canvas_color: #F8F8F8;
.ls-create-profile {
  padding: 0 0 0;
  color: #595856;
  .panel-container {
    display: flex;
    flex-direction: row;
    //flex-wrap: wrap;
    @media (max-width: variables.$device_sm-max) {
      flex-direction: column;
    }

    .page-panel {
      &.l2 {
        min-height: 165px;
      }
      &.w65 {
        flex-basis: 65%;
      }
      &.w35 {
        flex-basis: 35%;
      }
      //flex: 1;
      //margin: 3px;
      position: relative;
      .comment {
        width: 100%;
        height: 150px;
      }

      &.profile-2d, &.profile-3d {
        @media (max-width: variables.$device_sm-max) {
          flex: 1 100%;
        }
      }
      &.info {
        flex: 1 100%;
      }

      &.widget-container {
        flex: 1 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .ls-widget {
          flex-wrap: wrap;
          width: 50%;
          @media (max-width: variables.$device_sm-max) {
            width: 50%;
          }
          @media (max-width: variables.$device_sm-max) {
            flex-direction: column;
          }
          .ls-widget__name {
            flex-basis: 40%;
          }
          .ls-widget__value {
            flex-basis: 60%;
            line-height: 32px;
            padding-right: 0;
          }
        }
        &.center {
          .ls-widget {
            width: 100%;
            margin: auto;
            @media (max-width: variables.$device_sm-max) {
              width: 100%;
            }
            .ls-widget__name {
              flex-basis: 33.33%;
            }
            .ls-widget__value {
              flex-basis: 66.67%;
              padding-right: 0;
            }
          }
        }
      }
      .ls-tabs {
        margin-top: -5px;
        max-width: inherit;
        &__button {
          + label {
            border: 1px solid color.adjust($_canvas_color, $lightness: -20%);
            border-top: none;

            &:not(:first-of-type) {
              margin-left: 2px;
            }
          }
          &:checked + label {
            background-color: #F8F8F8;
            color: black;
          }
          &:not(checked) + label {
            background-color: var(--page_header_bg-color);
            color: white;
          }
        }
      }
    }
  }
  .width60 {
    width: 60%;
    @media (max-width: variables.$device_xs-max) {
      width: 100%;
    }
  }

  .controls-block {
    margin-bottom: 10px;
    margin-left: 3px;
    margin-right: 3px;
    .controls-rotate {
      float: right;
    }
    .button_tool {
      margin-bottom: 2px;
      @media (max-width: variables.$device_xs-max) {
        padding: 0 6px;
      }
    }
  }

  .create-profile-controls {
    display: flex;
    flex-wrap: nowrap;
  }
  .ls-profile {
    canvas {
      border: 1px solid color.adjust($_canvas_color, $lightness: -20%);
      &.editMode {
        border-bottom: none;
      }
    }

    .ls-profile_3d > canvas, .ls-profile_unwrap > canvas {
      border-bottom: none;
    }
    @media (max-width: variables.$device_xs-max) {
      display: block;
    }
  }
}
