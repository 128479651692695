// Оформление печатной формы счета на оплату
.ls-page-print-bill {
  // PARAMETERS
  $table-cell_padding: 1.5mm;
  $table_border-width: 1px;

  // MIXINS
  @mixin bill-table($border_width: 0) {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;

    thead th, tbody td {
      padding: $table-cell_padding;
      border: $border_width solid black;
    }
    tfoot {
      display: table-row-group;
    }
  }

  // STYLES
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 9pt;

  @media screen {
    padding: 20mm 15mm 20mm 15mm;
  }

  &__img {
    max-height: 80px;
    max-width: 100%;
    margin: 10px auto;
    display: block;
  }

  // Предупредительная подпись в шапке документа
  &__head-alert {
    font-size: 8pt;
    text-align: center;
    margin-bottom: 3mm;
  }

  //the logo of the company on top
  &__top-logo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3mm;

    img {
      max-height: 25mm;
    }
  }

  &__top-logo_large img {
    max-height: 50mm;
  }

  // Форма платежной накладной
  &__payment {
    margin-bottom: 10mm;
  }

  // Заголовок счета: "СЧЕТ №...."
  &__title {
    font-size: 14pt;
    font-weight: var(--font-weight_bolder);
    margin-bottom: 4mm;
  }

  // Информация о поставщике и покупателе
  &__info {
    @include bill-table();

    td {
      border: none;
    }

    td:first-child {
      font-weight: var(--font-weight_bolder);
    }
  }

  // Детализация счета
  &__details {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;

    tfoot {
      page-break-inside: avoid;
    }

    // При "border-collapse: collapse;" некорректно рисуются верхняя и левая границы таблицы при печати на несколько страниц
    thead th, tbody td {
      border-right: $table_border-width solid black;
      border-bottom: $table_border-width solid black;
      padding: $table-cell_padding;

      &:first-of-type {
        border-left: $table_border-width solid black;
      }
    }

    thead tr:first-of-type th {
      border-top: $table_border-width solid black;
    }

    // Заголовки таблицы должны быть жирными
    th {
      font-weight: var(--font-weight_bolder);
      text-align: center;
    }

    // 5-й и далее сталбцы выводят текст по правому краю
    tbody td:nth-child(n+5) {
      text-align: right;
    }

    // 1, 3-4 столбцы выводят текст по центру
    tbody td:first-child, tbody td:last-child, tbody td:nth-child(n+3):nth-child(-1n+4) {
      text-align: center;
    }

    // Оформление вывода подвала
    tfoot {
      display: table-row-group;
    }

    tfoot td {
      text-align: right;
      font-weight: var(--font-weight_bolder);
    }

    tfoot tr:first-of-type td {
      padding-top: 2mm;
    }
  }

  &__parameters {
    font-size: 11pt;
  }

  &__details-number {
    text-align: right;
    display: inline-block;
    width: 18mm;
    padding-right: $table-cell_padding;
  }

  // Сумма прописью
  &__total-sum-info {
    @include bill-table();
    //margin-bottom: 36mm;
    position: relative;
    margin-top: 7mm;
  }

  &__paid-online:after {
    content: "ОПЛАЧЕНО";
    font-weight: bolder;
    font-size: 45pt;
    border: 5pt solid #00a0e3;
    color: #00a0e3;
    padding: 5pt;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }

  // Подпись
  //&__signature {
  //  @include bill-table();
  //  font-weight: var(--font-weight_bolder);
  //  td + td {
  //    text-align: right;
  //  }
  //}
}
