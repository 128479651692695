// Variables
$_className: '.ls-radio';
$_check-height: 21px;
$_check-width: 33px;

#{$_className} {
  // Styles
  display: none;
}

input#{$_className} + label {
  background: var(--radio_unchecked_image) 0 0 no-repeat transparent;
  min-height: $_check-height;
  line-height: $_check-height;
  padding: 0 0 0 $_check-width;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  color: var(--text_base_color);
}

input#{$_className}:checked + label {
  background: var(--radio_checked_image) 0 0 no-repeat transparent;
}

input#{$_className}_right + label {
  background-position: right 0;
  padding: 0 $_check-width 0 0;
}

input#{$_className}:checked#{$_className}_right + label {
  background-position: right;
}
