@use '../settings/mixins';

$_className: '.radio-button';

input#{$_className} {
  display: none !important;
}

input#{$_className} + label {
  @include mixins.button-background-color(var(--page_bg-color), var(--page_bg-color--darken_10));

  & {
    box-shadow: inset 0 3px 3px var(--page_bg-color--darken_5);
    vertical-align: middle;
    display: inline-block;
    font-size: 13px;
    height: 22px;
    line-height: 22px;
    padding: 0 15px;
    cursor: pointer;
    text-align: center;
    color: var(--text_base_color);
  }

  //&:not(:first-of-type) {
  //  margin-left: -4px;
  //}
}

input#{$_className}:checked + label {
  background-color: var(--page_header_bg-color);
  box-shadow: inset 0 -3px 3px var(--page_header_bg-color--darken_5);
  color: #ffffff;
}

// Modifiers
input#{$_className}_full-width + label {
  width: 100%;
}

input#{$_className}_green:checked + label {
  background-color: var(--text_success_color);
  box-shadow: inset 0 -3px 3px var(--text_success_color--darken_5);
}

input#{$_className}_red:checked + label {
  background-color: var(--text_alert_color);
  box-shadow: inset 0 -3px 3px var(--text_alert_color--darken_5);
}
