@use '../settings/variables';

.ls-mat-dialog {
  font-size: 15px;
  color: var(--text_base_color);
  font-weight: var(--font-weight_normal);
  max-width: 100%;
  min-width: 350px;
  padding: 0 var(--modal_padding);

  &:before, &:after {
    // Реализация отступа сверху и снизу в процентах от собственной ширины
    content: '';
    display: block;
    padding: var(--modal_padding) 0 0;
  }

  &__message {
    margin: 15px var(--modal_padding--negative);
  }

  &__message_alert {
    background-color: var(--text_alert_color);
    color: #ffffff;
    min-height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
  }

  &__body {
    flex-grow: 1;
    border: none;
  }

  &__actions {
    padding: 0;
    margin: 4px 0 0 !important; // [!important] - Fix MaterialDialog v15
    justify-content: center;
  }

  &__action {
    flex-grow: 1;
    width: 100px;
    margin: 4px;

    @media (min-width: variables.$device_md-min) {
      max-width: 50%;
    }
  }
}
