@use 'sass:math';
@use './variables';

@mixin center-column() {
  width: 100%;
  margin: 0 auto;
  @media (min-width: variables.$device_md-min) {
    min-width: variables.$device_md;
    max-width: variables.$device_lg;
  }
}

// Выравнивает по вертикали все дочерние элементы
@mixin vertical-align($value: middle) {
  &:before {
    content: '\200B';
    display: inline-block;
    height: 100%;
    vertical-align: $value;
  }

  & > * {
    vertical-align: $value;
  }
}

// Выравнивает по вертикали строки с разными рамерами шрифта
// Блочным элементам в строке надо выставить "vertical-align: middle;"
// @font-size - задает размер шрифта под который нужно ровнять текст
@mixin vertical-align-text($font-size: 20px) {
  &:after {
    content: '\200B';
    font-size: $font-size;
    display: inline-block;
    height: 100%;
  }
}

@mixin button-background-color($color, $color_hover) {
  & {
    background-color: $color;
  }

  &:hover, &:focus {
    background-color: $color_hover;
    outline: 0;
  }
}

// micro clearfix hack: http://nicolasgallagher.com/micro-clearfix-hack/
@mixin micro-clearfix() {
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

// Базовое элемента управления форм
@mixin form_control-invalid() {
  border: 1px solid var(--control_error_border-color);
  background-color: var(--control_error_border-color--lighten_15);
  &:hover, &:focus {
    border-color: var(--control_error_border-color--darken_10);
    background-color: var(--control_error_border-color--lighten_8);
  }
}

@mixin form_control-valid() {
  border: 1px solid var(--control_valid_border-color);
  border-radius: 2px;
  outline: none;
  background-color: white;

  &, input, textarea, select, button {
    font-weight: var(--font-weight_normal);
  }

  &:hover {
    border-color: var(--control_valid_border-color--darken_10);
  }
  &:focus {
    border-color: var(--page_header_bg-color);
  }
}

@mixin form-control() {
  color: inherit;
  outline: none;
  box-shadow: none;
  @include form_control-valid();

  &:invalid, &.ng-invalid {
    @include form_control-invalid();
  }
}

// Превращает элемент в стрелку
// $rotate-angle - угол поворота стрелки
// $item-size - размер стрелки
// $line-width - толщина линии
@mixin arrow($rotate-angle, $item-size: 30px, $line-width: 1px) {
  display: inline-block;
  transform: rotate($rotate-angle);

  vertical-align: middle;
  border: solid;
  border-width: $line-width $line-width 0 0;
  overflow: hidden;
  width: $item-size * 0.75;
  height: $item-size * 0.75;
}

// Превращает элемент в крестик
// $size - размер стороны квадрата занимаемого крестиком
// $color - цвет линий
// $line-width - толщина линии
@mixin cross($size: 25px, $color: var(--page_header_bg-color), $color_hover: var(--page_header_bg-color--darken_20), $line-width: 1px) {
  & {
    cursor: pointer;
    position: relative;
    width: $size;
    height: $size;
    padding: 0;
    color: $color;
  }

  &:hover, &:focus {
    color: $color_hover;
  }
  &:before, &:after {
    content: '';
    position: absolute;
    border-top: $line-width solid;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: math.div(-$line-width, 2);
    transition-duration: 0.5s;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:hover:before {
    transform: rotate(135deg);
  }
  &:hover:after {
    transform: rotate(45deg);
  }
}

@mixin fold($size: 25px, $color: var(--page_header_bg-color), $line-width: 1px, $button-down: true) {
  $button-type: if($button-down, 1, -1);
  cursor: pointer;
  position: relative;
  width: $size;
  height: $size;
  display: block;

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    width: $line-width;
    height: $size * 0.7 - $line-width * 0.5;
    border-radius: $line-width;
    background-color: $color;
    transition-property: width, height, left, right, top, transform;
    transition-duration: 500ms;
    transition-timing-function: ease-in-out;
  }

  &:before {
    transform: rotate(-45deg * $button-type);
    top: $size * 0.20;
    left: $size * 0.25;
  }

  &:hover::before {
    transform: rotate(135deg * $button-type);
  }

  &:after {
    transform: rotate(45deg * $button-type);
    top: $size * 0.20;
    right: $size * 0.25;
  }

  &:hover::after {
    transform: rotate(-135deg * $button-type);
  }

  &.down {
    &:before {
      transform: rotate(45deg * $button-type);
    }

    &:hover::before {
      transform: rotate(-135deg * $button-type);
    }

    &:after {
      transform: rotate(-45deg * $button-type);
    }

    &:hover::after {
      transform: rotate(135deg * $button-type);
    }
  }
}

@mixin fold2($size: 25px, $color: var(--page_header_bg-color), $line-width: 1px, $button-down: false) {
  $button-type: if($button-down, 1, -1);
  cursor: pointer;
  position: relative;
  width: $size;
  height: $size;
  display: block;

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    width: $line-width;
    height: $size * 0.7 - $line-width * 0.5;
    border-radius: $line-width;
    background-color: $color;
    transition-property: width, height, left, right, top, transform;
    transition-duration: 500ms;
    transition-timing-function: ease-in-out;
  }

  &:before {
    transform: rotate(-45deg * $button-type);
    top: $size * 0.20;
    left: $size * 0.25;
  }
  &:hover::before {
    transform: rotate(135deg * $button-type);
  }

  &:after {
    transform: rotate(45deg * $button-type);
    top: $size * 0.20;
    right: $size * 0.25;
  }
  &:hover::after {
    transform: rotate(-135deg * $button-type);
  }

  &.down {
    &:before {
      height: $size;
      top: 0;
      left: $size * 0.5 - $line-width * 0.5;
      transform: rotate(135deg * $button-type);
    }

    &:hover::before {
      transform: rotate(45deg * $button-type);
    }

    &:after {
      height: $size;
      top: 0;
      right: $size * 0.5 - $line-width * 0.5;
      transform: rotate(-135deg * $button-type);
    }

    &:hover::after {
      transform: rotate(-45deg * $button-type);
    }
  }
}

// Настройка области перемотки
@mixin scroll-view($view_height: 200px, $overview_padding: 0) {
  height: $view_height;
  //padding-right: 10px; // space for scroll-bar

  & > .scroll-bar {
    border-radius: 0;
    border: 1px solid var(--table_header_bg-color--darken_8);
    background: #fff;
    width: 10px;

    & > .scroll-thumb {
      left: -1px;
      border-radius: 0;
      background: var(--table_header_bg-color--darken_8);
      width: 10px;
    }
  }

  & > .scroll-viewport {
    height: 100%;

    & > .scroll-overview {
      width: 100%;
      padding: $overview_padding;
    }
  }
}
