@use '../settings/variables';
@use '../settings/mixins';

// Сборник вспомогательных классов

//==========================
// Скрыть элемент
.lsu-hide {
  display: none !important;
}

// Скрывать элемент на очень маленьких устройствах
.lsu-hide-xs {
  @media (max-width: variables.$device_xs-max) {
    display: none !important;
  }
}

// Скрывать элемент на маленьких, очень маленьких устройствах
.lsu-hide-sm {
  @media (max-width: variables.$device_sm-max) {
    display: none !important;
  }
}

// Скрывать элемент на средних, маленьких, очень маленьких устройствах
.lsu-hide-md {
  @media (max-width: variables.$device_md-max) {
    display: none !important;
  }
}

//==========================
// Оформить элемент как заголовок страницы
.lsu-h1-like {
  font-weight: var(--font-weight_normal);
  color: var(--page_header_bg-color);
  font-size: 26px;
  margin: 0;
}

//==========================
// Элемент пользовательского ввода
.lsu-valid {
  @include mixins.form_control-valid();
}

.lsu-invalid {
  @include mixins.form_control-invalid();
}

.lsu-control {
  @include mixins.form-control();

  & {
    height: var(--control_height);
    min-height: var(--toolbar_height);
    max-height: 100%;
    padding: 0 10px;
    max-width: 100%;
  }
}

// Элемент управления для ввода количества
.lsu-control-qty {
  @include mixins.form-control();

  & {
    height: var(--control_height);
    width: var(--control-qty_width);
    min-height: var(--toolbar_height);
    max-height: 100%;
    max-width: 100%;
    text-align: center;
  }
}

//==========================
// Text helpers
.lsu-text-center {
  text-align: center;
}

.lsu-text-alert {
  color: var(--text_alert_color);
}

.lsu-text-success {
  color: var(--text_success_color);
}

.lsu-highlight {
  font-weight: var(--font-weight_bold);
}

//==========================
// Отступы в дереве элементов
// Вспомогательный класс для быстрой разметки элементов с отступами
.lsu-tree-item {
  @for $level from 0 through 6 { // Генерация уровней 0-6
    &-level-#{$level} {
      display: block;
      margin-left: $level * 5%;
    }
  }
}

//=================
// Сделать элемент кликабельным
.lsu-clickable {
  cursor: pointer;
}

.lsu-action {
  color: var(--table_header_color);
  cursor: pointer;
}

//=================
// Set loading background
.lsu-loading {
  background-image: url('/assets/loader_button.gif');
}

//=================
// Set image limits
.lsu-image {
  max-width: 100%;
}

//=================
// Cursors
.lsu-cursor-help {
  cursor: help !important;
}

.lsu-cursor-move {
  cursor: move !important;
}

//=================
// Mark experimental functions
.lsu-experimental {
  position: relative;
  margin: 0 var(--block_space);
  color: var(--text_alert_color);
  font-weight: var(--font-weight_bold);
  text-align: right;

  &:before {
    content: 'experimental';
    display: inline;
    position: absolute;
    top: 100%;
    right: 50%;
    z-index: 1;
  }
}

.lsu-dummy {
  position: absolute;
  left: -100vw;
}
