@use '../settings/variables';

.ls-table {
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
  border-bottom: 1px solid var(--table_border_color);
  position: relative;

  thead {
    font-size: 14px;
    background-color: var(--table_header_bg-color);
  }

  //tbody, tr, tr svg {
  //  cursor: inherit !important;
  //}

  tbody {
    tr td {
      border-bottom: 1px solid transparent;
    }

    tr:first-of-type td {
      border-top: 1px solid transparent;
    }

    tr:not(:first-of-type) td {
      border-top: 1px solid var(--block_divider_color);
    }
  }

  td, th {
    font-weight: var(--font-weight_normal);
    padding-top: 6px;
    padding-bottom: 6px;
    overflow: hidden;

    @media (max-width: variables.$device_sm-max) {
      padding-left: 0.5%;
    }
    @media (min-width: variables.$device_md-min) {
      padding-left: 1%;
    }
  }

  th {
    color: var(--table_header_color);
    height: var(--table_header_height);
    text-align: left;
    overflow: hidden;
  }

  td {
    color: var(--text_base_color);
    height: 40px;
  }

  // Модификатор для отображения в таблице записей помеченных на удаление
  &_deleted td {
    color: var(--text_base_color--lighten_20);
  }

  // Модификатор добавляющий выделение строк при наведении мышки
  &_selectable {
    // Реализация подсветки строк при наведении мышки
    tbody {
      tr:hover td {
        cursor: pointer;
        background-color: var(--table_active-row_bg-color);
      }

      tr:not(:last-of-type):hover td {
        border-bottom: 1px solid var(--table_border_color);
      }

      tr:not(:first-of-type):hover td {
        border-top: 1px solid var(--table_border_color);
      }

      tr:hover + tr td {
        border-top: 1px solid transparent;
      }
    }
  }

  // Mark selected row or something
  &__edited {
    background-color: var(--table_selected-row_bg-color);
  }

  // Отображение ссылки в таблице
  &__link {
    color: inherit;
    text-decoration: none;
  }
  &__link_blocked {
    color: var(--text_alert_color);
  }

  // Для отображения дробной части числа в таблице
  &__fraction {
    font-size: 13px;
  }

  // Для установки ширины колонки с кнопками
  &__col-button {
    width: 30px;
    min-width: 30px;
    text-align: right;
    padding: 0 !important;
  }

  // Колонка для отображения количества
  &__col-qty {
    width: var(--control-qty_width);
    text-align: center;
    padding: 0 !important;
  }

  &__col-status {
    font-size: 13px;
  }

  &__col-multi {
    display: flex;
  }

  &__col-main {
    flex-shrink: 2;
    flex-basis: 100%;
    padding-right: 1px;
  }

  &__col-util3 {
    flex-shrink: 3;
  }

  &__col-util5 {
    flex-shrink: 5;
  }

  // Колонка с артикулом
  &__col-inv {
    width: 115px;
  }
}
