.ls-list {
  & li {
    cursor: pointer;
    padding: 2px 5px;
    border-radius: 2px;
    transition: background-color .15s, box-shadow .15s;
    &:hover {
      box-shadow: inset 0 0 1px #999;
      background: #f7fbff;
    }
  }

  &__active {
    background-color: #beebff;
  }
}