@use '../settings/variables';

// Блок с элементами в общем виде
.ls-block {
  $_className: '.ls-block';

  color: var(--text_base_color);
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;

  // Модификатор - линейный блок
  &_linear {
    flex-wrap: nowrap;
  }

  &_column {
    flex-direction: column;
  }

  // Modifier for arrangement to left
  &_left {
    justify-content: flex-start;
  }

  // Модификатор для центрирования содержимого блока
  &_center {
    justify-content: space-around;
  }

  // Modificator for justify content to right
  &_right {
    justify-content: flex-end;
  }

  // Простой элемент
  &__item {
    margin: var(--block_space) !important; // [!important] - Fix MaterialDialog v15
  }

  // Элемент пользовательского ввода
  &__item-control {
    //flex-shrink: 0;
    margin: var(--block_space);
    display: flex;
    align-items: center;
    min-height: var(--toolbar_height);
    & > * {
      flex-grow: 1;
    }
  }

  // Группа элементов
  &__group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @media (max-width: variables.$device_xs-max) {
      width: 100%;
    }
  }
  &__group_stretch {
    align-items: stretch;
  }
  &__group_shrink {
    flex-shrink: 10;
  }
  &__group_grow {
    flex-grow: 1;
  }
  &__group_grow #{$_className}__item, &__group_grow #{$_className}__item-control {
    flex-grow: 1;
  }
}
