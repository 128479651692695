.ngx-bar {
  background: var(--base-background-color) !important;
  box-shadow: var(--page_footer_bg-color) 1px 0 6px 1px;
}

.ngx-spinner .ngx-spinner-icon {
  border-top-color: var(--base-background-color);
  border-left-color: var(--base-background-color);
  color: white;
}
