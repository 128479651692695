@use '../settings/mixins';

.ls-tabs {
  // Variables
  $_className: '.ls-tabs';
  $tab-border-color: var(--page_header_bg-color);

  // Styles
  width: 100%;
  display: block;
  //max-width: 500px;
  line-height: normal;

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
  }

  &__button {
    display: none;

    & + label {
      flex-grow: 1;
      padding: 10px 1vh;
      cursor: pointer;
      text-align: center;
      font-size: 15px;
      border-top: 1px solid $tab-border-color;
      color: var(--text_base_color);
      font-weight: var(--font-weight_normal);

      &:not(:first-of-type) {
        border-left: 1px solid $tab-border-color;
      }
    }

    &:not(:checked):not(#{$_className}__invalid) + label {
      background-color: var(--table_header_bg-color);
      border-bottom: 1px solid $tab-border-color;

      &:hover, &:focus {
        background-color: var(--table_header_bg-color--darken_5);
      }
    }

    &:checked + label {
    }
  }

  &__invalid:not(:checked) + label {
    @include mixins.form_control-invalid();
  }

  &__views {
    width: 100%;
    display: block;
  }

  &__view {
    font-size: 15px;
  }
}
