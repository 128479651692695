.ui-fieldset {
  $_className: '.ui-fieldset';
  margin: 4px 0;
  border: 1px solid var(--block_divider_color);

  & > legend {
    padding: 0 5px;
    margin-left: 10px;
    font-size: 17px;
    font-weight: var(--font-weight_normal);
    color: var(--page_header_bg-color);
  }

  &__body {
    padding: 0 5px 5px;
  }

  // Модификатор. Группа свернута
  &_collapsed {
    border-width: 1px 0 0;
  }

  &_collapsed > #{$_className}__body {
    display: none;
  }
}
