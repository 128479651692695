@use '../../settings/mixins';

.order-pickup-date {
  &__input {
    input {
      @include mixins.form-control();

      & {
        height: var(--control_height);
        min-height: var(--toolbar_height);
        max-height: 100%;
        padding: 0 10px;
        max-width: 100%;
      }
    }

    &--invalid {
      input {
        @include mixins.form_control-invalid();
      }
    }
  }
}
