@use "sass:color";

// Default theme 'blue'
:root {

  // Main background color
  --base-background-color: white;

  // Текст
  --text_base_color: #595856; // Base text color
  --text_base_color--darken_20: #{color.adjust(#595856, $lightness: -20%)}; // Base text color - darken 20%
  --text_base_color--lighten_20: #{color.adjust(#595856, $lightness: 20%)}; // Base text color - lighten 20%
  --text_base_color--lighten_25: #{color.adjust(#595856, $lightness: 25%)}; // Base text color - lighten 25%

  --text_success_color: #65b94a; // Success text color
  --text_success_color--darken_5: #{color.adjust(#65b94a, $lightness: -5%)}; // Success text color - darken 5%
  --text_success_color--darken_20: #{color.adjust(#65b94a, $lightness: -20%)}; // Success text color - darken 20%

  --text_alert_color: #e44108; // Warning text color
  --text_alert_color--darken_5: #{color.adjust(#e44108, $lightness: -5%)}; // Warning text color - darken 5%
  --text_alert_color--darken_20: #{color.adjust(#e44108, $lightness: -20%)}; // Warning text color - darken 20%

  // Font
  --font-size__placeholder: 0.88rem; // font size of placeholders
  --font-weight_normal: 300; // Regular font weight
  --font-weight_bold: 400; // Bold font weight
  --font-weight_bolder: 600; // More bold font weight

  // Page
  --page_bg-color: #f3f3f3;
  --page_bg-color--darken_5: #{color.adjust(#f3f3f3, $lightness: -5%)};
  --page_bg-color--darken_10: #{color.adjust(#f3f3f3, $lightness: -10%)};

  --page_header_bg-color: #5099cf;
  --page_header_bg-color--darken_5: #{color.adjust(#5099cf, $lightness: -5%)};
  --page_header_bg-color--darken_10: #{color.adjust(#5099cf, $lightness: -10%)};
  --page_header_bg-color--darken_15: #{color.adjust(#5099cf, $lightness: -15%)};
  --page_header_bg-color--darken_20: #{color.adjust(#5099cf, $lightness: -20%)};
  --page_header_bg-color--lighten_10: #{color.adjust(#5099cf, $lightness: 10%)};
  --page_header_height: 64px;

  --page_footer_bg-color: #696969;
  --page_footer_height: 55px;

  // Authorization Page
  --page-auth_header_height: 70px;
  --page-auth_footer_height: 45px;

  // Main menu
  --menu-top_active-link_color: #445059;
  --menu-top_sub-link_color: #272727;
  --menu-top_active-icon_color: #3382bc;

  // Menu panel
  --menu-panel_link_color: #04538d;

  // User menu
  --user-menu_icon-man_bg-color: #6aa9d8;

  // Tables
  --table_header_color: #163b6a; // Table header text color

  --table_header_bg-color: #dcedf9; // Table header background color
  --table_header_bg-color--darken_5: #{color.adjust(#dcedf9, $lightness: -5%)};
  --table_header_bg-color--darken_8: #{color.adjust(#dcedf9, $lightness: -8%)};
  --table_header_bg-color--lighten_5: #{color.adjust(#dcedf9, $lightness: 5%)};

  --table_header_height: 42px; // Table header height
  --table_border_color: #77afd8; // Table header height
  --table_active-row_bg-color: #f0f9ff; // Table active row background
  --table_selected-row_bg-color: #{color.adjust(#dcedf9, $lightness: 3%)}; // Table selected row background

  // Modal window
  --modal_padding: 2vw; // Modal window paddings
  --modal_padding--negative: -2vw; // Negative modal window paddings

  // Элементы управления
  --control_disabled_bg-color: #d7d7d7; // Disabled control background

  --control_valid_border-color: #c4c4cb; // Valid control border color
  --control_valid_border-color--darken_10: #{color.adjust(#c4c4cb, $lightness: -10%)}; // Valid control border color - darken 10%

  --control_error_border-color: #ffa6a6; // Invalid control border color
  --control_error_border-color--darken_10: #{color.adjust(#ffa6a6, $lightness: -10%)}; // Invalid control border color - darken 10%
  --control_error_border-color--lighten_8: #{color.adjust(#ffa6a6, $lightness: 8%)}; // Invalid control border color - lighten 8%
  --control_error_border-color--lighten_15: #{color.adjust(#ffa6a6, $lightness: 15%)}; // Invalid control border color - lighten 15%

  --control_height: 34px; // Control height
  --control-qty_width: 60px; // Width of "quantity input" control
  --image_search: url('/assets/search.png'); // Search image

  // Controls - check-box
  --checkbox_unchecked_image: url('/assets/checkbox_clear.png');
  --checkbox_checked_image: url('/assets/checkbox_checked-blue.png');
  --checkbox_indeterminate_image: url('/assets/checkbox_indeterminate-blue.png');

  // Control - radio-button
  --radio_unchecked_image: url('/assets/radio_clear.png');
  --radio_checked_image: url('/assets/radio_checked-blue.png');

  // Toolbar
  --toolbar_height: 30px;

  // Block
  --block_divider_color: #e8e8e8; // Color of line separating blocks
  --block_space: 4px;
  --block_space--double: 8px;

  // Tooltip
  --tooltip_bg-color: #faffbd;
  --tooltip_bg-color--darken_40: #{color.adjust(#faffbd, $lightness: -40%)};

  // Stickers
  --print-page-sticker_width: 57mm;                            // Ширина этикетки
  --print-page-sticker_height: 57mm;                           // Высота этикетки
  --print-page-sticker_padding: 2mm;                           // Отступы этикетки
  --sticker-separate_image: url('/assets/angle-black.png');  // Image of the place where the labels are torn off
  --sticker-separate_image_negative: url('/assets/angle-white.png');  // Image of the place where the labels are torn off (inverted color)
  --sticker-separate_image-size: 4%;                           // Size of the image of the place where the labels are torn off

  // Documents
  --print-page-A4_width: 209mm;    // Page A4 height
  --print-page-A4_height: 296mm;   // Page A4 width
}
