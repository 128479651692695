// Блок с зеленым текстом
.text-success {
  color: var(--text_success_color);
}

// Блок с красным текстом
.text-alert {
  color: var(--text_alert_color);
}

.text-warning {
  color: var(--text_alert_color);
  font-style: italic;
  font-size: 16px;
}
