@use '../settings/mixins';

// Форма редактирования скидок
.form-discount {
  // Переменные
  $row_height: 40px;

  // Стили
  width: 600px;
  max-width: 100%;

  &__table-head {
    border-bottom: none;
  }

  &__table-body {
    border-bottom: none;

    td {
      border: none !important;
    }
  }

  &__view {
    @include mixins.scroll-view($row_height * 7);
  }

  &__view-underline {
    height: 3px;
    border-bottom: 1px solid var(--table_border_color);
  }

  &__col-type {
    width: 40%;
  }

  &__col-default {
    td#{&}, th#{&} {
      text-align: center;
    }

    width: 30%;
  }

  &__col-personal {
    text-align: center;
    width: 30%;
  }

  &__control {
    @include mixins.form-control();

    & {
      width: 60px;
      height: var(--control_height);
      text-align: center;
    }
  }
}
