@use '../../settings/mixins';

.order-contragent {
  // Variables
  $caption-width: 100px;
  $delivery-width: 120px;

  // Styles
  width: 100%;
  height: var(--control_height);
  color: var(--text_base_color);
  font-size: 16px;

  &__caption {
    @include mixins.vertical-align();

    & {
      float: left;
      width: $caption-width;
      height: 100%;
    }
  }

  &__select {
    font-weight: var(--font-weight_normal);
    margin: 0 $delivery-width 0 $caption-width;
    height: 100%;
  }

  &__select-ctrl {
    width: 100%;
    height: 100%;
  }

  &__delivery {
    @include mixins.vertical-align();

    & {
      text-align: right;
      float: right;
      width: $delivery-width;
      height: 100%;
    }
  }

}
